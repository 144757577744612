import AuthenticationContext from 'adal-angular';
import { clientId } from '../config';

export const azureConfig = {
  instance: 'https://login.microsoftonline.com/',
  tenant: '57e289b5-527b-4356-b8cd-d990c1875a1b',
  clientId: clientId,
  // postLogoutRedirectUri: window.location.origin,
  //cacheLocation: 'localStorage', // enable this for IE, as sessionStorage does not work for localhost.
  endpoints: {
    graphApiUri: "https://graph.microsoft.com"
  }
};

export const authContext = new AuthenticationContext(azureConfig);
console.log('init authContext', authContext);

let isCallback = authContext.isCallback(window.location.hash);
console.log('isCallback', isCallback);

authContext.handleWindowCallback();

if (isCallback && !authContext.getLoginError()) {
  console.log('check login...');
  window.location = authContext._getItem(authContext.CONSTANTS.STORAGE.LOGIN_REQUEST);
}
