import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  findOrderList,
  findFittingList,
  findFittingNotEqualList,
  findDetailsNeedBespoke,
  findFittingRecord,
  saveFittingRecord,
  statOverviewCount,
  statOverviewDetail
} from './fittingAPI';

export const fetchFindOrderList = createAsyncThunk(
  'fitting/findOrderList',
  async (arg, thunkAPI) => {
    console.log('execute findOrderList');
    return await findOrderList();
  }
)

export const fetchFindFittingList = createAsyncThunk(
  'fitting/findFittingList',
  async (arg, thunkAPI) => {
    console.log('execute findFittingList');
    return await findFittingList(arg);
  }
)

export const fetchFindFittingNotEqualList = createAsyncThunk(
  'fitting/findFittingNotEqualList',
  async (arg, thunkAPI) => {
    console.log('execute findFittingNotEqualList');
    return await findFittingNotEqualList(arg);
  }
)

export const fetchFindDetailsNeedBespoke = createAsyncThunk(
  'fitting/findDetailsNeedBespoke',
  async (arg, thunkAPI) => {
    console.log('execute findDetailsNeedBespoke');
    return await findDetailsNeedBespoke(arg);
  }
)

export const fetchFindFittingRecord = createAsyncThunk(
  'fitting/findFittingRecord',
  async (arg, thunkAPI) => {
    console.log('execute findFittingRecord');
    return await findFittingRecord(arg);
  }
)

export const fetchSaveFittingRecord = createAsyncThunk(
  'fitting/saveFittingRecord',
  async (arg, thunkAPI) => {
    console.log('execute saveFittingRecord');
    return await saveFittingRecord(arg);
  }
)

export const fetchStatOverviewCount = createAsyncThunk(
  'fitting/statOverviewCount',
  async (arg, thunkAPI) => {
    console.log('execute statOverviewCount');
    return await statOverviewCount(arg);
  }
)

export const fetchStatOverviewDetail = createAsyncThunk(
  'fitting/statOverviewDetail',
  async (arg, thunkAPI) => {
    console.log('execute statOverviewDetail');
    return await statOverviewDetail(arg);
  }
)

export const fetchSearchFittingListByName = createAsyncThunk(
  'fitting/findFittingList',
  async (arg, thunkAPI) => {
    console.log('execute findFittingList');
    return await findFittingList(arg);
  }
)

export const fittingSlice = createSlice({
  name: 'fitting',
  initialState: {
    orderList: [],
    orderId: 0,
    searchName: '',
    tableData: [],
    activePage: 1,
    totalPage: 1,
    detailsNeedBespoke: [],
    selectedSuitId: 0,
    selectedSuit: { suitId: 0 },
    fittingRecord: [],
    showSaveFittingRecord: false,
    alertStyle: '',
    alertMessage: '',
    toastAlertStyle: '',
    toastAlertMessage: '',
    statOverviewCount: [],
    statOverviewDetail: []
  },
  reducers: {
    changeOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    changeActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    changeSelectedSuitId: (state, action) => {
      state.selectedSuitId = action.payload;
    },
    changeSuitRecord: (state, action) => {
      state.selectedSuit[action.payload.type] = action.payload.value
    },
    changeFittingRecord: (state, action) => {
      // console.log(action);
      let row = action.payload.row;
      let type = action.payload.type;
      let value = action.payload.value;
      let rowData = state.fittingRecord[row];
      rowData[type] = value;
      state.fittingRecord[row] = rowData;
    },
    changeShowSaveFittingRecord: (state, action) => {
      state.showSaveFittingRecord = action.payload;
    },
    clearAlert: (state, action) => {
      state.alertStyle = '';
      state.alertMessage = '';
      state.toastAlertStyle = '';
      state.toastAlertMessage = '';
    },
    changeSearchName: (state, action) => {
      state.searchName = action.payload;
  },
  },
  extraReducers: {
    [fetchFindOrderList.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.orderList = action.payload.data;
      console.log('end findOrderList.fulfilled');
    },
    [fetchFindFittingList.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.tableData = action.payload.data.dataTableData;
      state.activePage = action.payload.data.activePage;
      state.totalPage = action.payload.data.totalPage;
      console.log('end findFittingList.fulfilled');
    },
    [fetchFindFittingNotEqualList.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.tableData = action.payload.data.dataTableData;
      state.activePage = action.payload.data.activePage;
      state.totalPage = action.payload.data.totalPage;
      console.log('end findFittingNotEqualList.fulfilled');
    },
    [fetchFindDetailsNeedBespoke.fulfilled]: (state, action) => {
      // console.log(action.payload);
      if (action.payload.statusCode === '0') {
        state.alertStyle = '';
        state.alertMessage = '';
        state.detailsNeedBespoke = action.payload.data;
      } else {
        state.alertStyle = action.payload.alertStyle;
        state.alertMessage = action.payload.displayMessage;
      }
      console.log('findDetailsNeedBespoke.fulfilled');
    },
    [fetchFindFittingRecord.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.fittingRecord = action.payload.data.fittingList;
      state.selectedSuit = action.payload.data.fittingMap;
      console.log('findFittingRecord.fulfilled');
    },
    [fetchSaveFittingRecord.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.showSaveFittingRecord = true;
      state.toastAlertStyle = action.payload.alertStyle;
      state.toastAlertMessage = action.payload.displayMessage;
      if (action.payload.alertStyle === 'success') {
        state.fittingRecord = action.payload.data.fittingList;
        state.selectedSuit = action.payload.data.fittingMap;
      }
      console.log('saveFittingRecord.fulfilled');
    },
    [fetchStatOverviewCount.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.statOverviewCount = action.payload.data;
      console.log('statOverviewCount.fulfilled');
    },
    [fetchStatOverviewDetail.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.statOverviewDetail = action.payload.data;
      console.log('statOverviewCount.fulfilled');
    }
  },
});

export const {
  changeOrderId,
  changeActivePage,
  changeSelectedSuitId,
  changeSuitRecord,
  changeFittingRecord,
  changeShowSaveFittingRecord,
  clearAlert,
  changeSearchName
} = fittingSlice.actions;

export default fittingSlice.reducer;