import axios from 'axios'

import { apiHost } from '../config';
import { authContext } from './AzureAd';

const createParam = (method, url, header, body, token) => {
    let _headers = header;

    if (_headers === undefined) {
        _headers = {
            'Accept': 'application/json;charset=UTF-8',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }

    if (token) {
        _headers['Authorization'] = 'Bearer ' + token;
        // console.log(token);
    } else {
        console.err('No token!');
        return;
    }

    let _param = {};
    let _method = method.toUpperCase();
    if (_method === 'GET' || _method === 'HEAD') {
        _param = {
            method: _method,
            url: apiHost + url,
            headers: _headers,
            withCredentials: false
        };
    } else {
        _param = {
            method: _method,
            url: apiHost + url,
            headers: _headers,
            data: body,
            withCredentials: false
        };
    }
    return _param;
}

export async function authAxios(method, url, body) {
    // Acquire token for Files resource.
    return new Promise((resolve, reject) => {
        authContext.acquireToken(authContext.config.clientId, function (error, token) {

            // Handle ADAL Errors.
            if (error) {
                console.err('ADAL error occurred: ' + error);
                return;
            }

            if (token == null) {
                console.warn('no token !!');
                console.warn(url);
                return;
            };
            // console.log(token);

            return axios(createParam(method, url, undefined, body, token))
                .then(function (response) {
                    resolve(response.data);
                    return response.data
                })
                .catch(function (error) {
                    console.log(error);
                    // TODO:
                    return {};
                });
        });
    });
}


export async function noAuthAxios(method, url, body) {

    let header = {
        'Accept': 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8'
    };

    let _param = {};
    if (method === 'GET' || method === 'HEAD') {
        _param = {
            method: method,
            url: apiHost + url,
            headers: header
        };
    } else {
        _param = {
            method: method,
            url: apiHost + url,
            headers: header,
            data: body
        };
    }

    return await axios(_param)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error);
            // TODO:
            return {};
        });
};