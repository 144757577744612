import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchFindOrderList,
  fetchFindFittingList,
  fetchFindFittingNotEqualList,
  changeOrderId,
  fetchStatOverviewCount,
  fetchStatOverviewDetail,
  changeSearchName,
  fetchSearchFittingListByName
} from './fittingSlice';

import styles from './Fitting.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import { FittingSuitList } from './FittingSuitList';
import { FittingCount } from './FittingCount';
import { FittingDetail } from './FittingDetail';

export function Fitting() {
  const [tabKey, setTabKey] = useState('suitList');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFindOrderList());
    return () => {
    };
  }, []);

  const orderList = useSelector(state => state.fitting.orderList);
  const orderId = useSelector(state => state.fitting.orderId);
  const searchName = useSelector(state => state.fitting.searchName);

  const handleSelectOrder = (selectedOrderId) => {
    dispatch(changeOrderId(selectedOrderId));

    if (tabKey === 'suitList') {
      let pagingData = { activePage: 1 }
      dispatch(fetchFindFittingList({ orderId: selectedOrderId, pagingData: pagingData, searchName: searchName }));
    } else if (tabKey === 'suitList2') {
      dispatch(fetchFindFittingNotEqualList({ orderId: selectedOrderId }));
    } else if (tabKey === 'count') {
      dispatch(fetchStatOverviewCount({ orderId: selectedOrderId }));
    } else if (tabKey === 'detail') {
      dispatch(fetchStatOverviewDetail({ orderId: selectedOrderId }));
    }
  }

  const handleSelectTab = (eventKey) => {
    if (eventKey === 'suitList') {
      setTabKey('suitList');
      let pagingData = { activePage: 1 }
      dispatch(fetchFindFittingList({ orderId: orderId, pagingData: pagingData, searchName: searchName }));
    } else if (eventKey === 'suitList2') {
      setTabKey('suitList2');
      dispatch(fetchFindFittingNotEqualList({ orderId: orderId }));
    } else if (eventKey === 'count') {
      setTabKey('count');
      dispatch(fetchStatOverviewCount({ orderId: orderId }));
    } else if (eventKey === 'detail') {
      setTabKey('detail');
      dispatch(fetchStatOverviewDetail({ orderId: orderId }));
    }
  }

  let searchDiv = [];
  if (tabKey === 'suitList' && orderId) {
    searchDiv = (
      <form className="d-flex">
        <input className="form-control me-2" type="search" placeholder="請輸入搜尋的姓名" aria-label="Search" value={searchName} onChange={
          (event) => {
            dispatch(changeSearchName(event.target.value));
          }
        } />
        <button className="btn btn-primary" type="button" onClick={
          (event) => {
            if (searchName) {
              dispatch(fetchSearchFittingListByName({
                orderId: orderId,
                searchName: searchName,
                pagingData: { activePage: 1 }
              }));
            } else {
              dispatch(fetchSearchFittingListByName({
                orderId: orderId,
                searchName: null,
                pagingData: { activePage: 1 }
              }));
            }
          }
        }><i className="fas fa-search"></i></button>
      </form>
    )
  }

  return (
    <Container fluid>
      <Row className="pt-2 pb-2">
        <Col sm="1">
          <Form.Label htmlFor="orderId"><b style={{ fontSize: '1.2rem' }}>訂單：</b></Form.Label>
        </Col>
        <Col sm="3">
          <Typeahead id="orderId"
            onChange={(selected) => {
              if (selected[0]) {
                handleSelectOrder(selected[0]);
              }
            }}
            options={orderList}
          />
        </Col>
        <Col sm="4">&nbsp;</Col>
        <Col sm="4">
          {searchDiv}
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="suitList"
        id="fitting-tabs"
        className="mb-3"
        onSelect={(eventKey) => handleSelectTab(eventKey)}
      >
        <Tab eventKey="suitList" title="套量列表">
          <FittingSuitList />
        </Tab>
        <Tab eventKey="suitList2" title="套量列表(未完成)">
          <span style={{ color: 'tomato' }}> 僅列出前 20 筆資料 </span>
          <FittingSuitList />
        </Tab>
        <Tab eventKey="count" title="套量總數">
          <FittingCount />
        </Tab>
        <Tab eventKey="detail" title="套量明細">
          <FittingDetail />
        </Tab>
      </Tabs>
    </Container>
  );
}