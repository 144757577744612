
export function colorDisplay(color) {
    let display = color;
    if ('Red' === color) {
        display = '紅';
    } else if ('Yellow' === color) {
        display = '黃';
    } else if ('Orange' === color) {
        display = '橘';
    } else if ('Navy Blue' === color) {
        display = '深藍';
    } else if ('Royal Blue' === color) {
        display = '寶藍';
    } else if ('Gold' === color) {
        display = '金';
    } else if ('Sand Yellow' === color) {
        display = '土黃';
    } else if ('Luminous' === color) {
        display = '夜光';
    } else if ('Yellowgreen' === color) {
        display = '黃綠';
    } else if ('White' === color) {
        display = '白';
    } else if ('Blue' === color) {
        display = '藍';
    }
    return display;
}

export function sizingDisplay(sizing) {
    let display = sizing;
    if ('Customized' === sizing) {
        display = '特';
    } else if ('Large' === sizing) {
        display = '大';
    } else if ('Small' === sizing) {
        display = '小';
    } else if ('Adjustable' === sizing) {
        display = '可調式';
    }
    return display;
}