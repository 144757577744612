import React from 'react';
import { useSelector } from 'react-redux';
import { SimpleDataTable } from '../../component/SimpleDataTable';

export function FittingCount() {

    const statOverviewCount = useSelector(state => state.fitting.statOverviewCount);
    const orderId = useSelector(state => state.fitting.orderId);
    const tableFields = {
        gear_display: '項目',
        quantity: '總件數',
        cnt: '已套量數'
    };
    const keys = ['order_detail_id'];

    let content = [];
    if (orderId) {
        content = (
            <SimpleDataTable
                fields={tableFields}
                data={statOverviewCount}
                keys={keys}
            />
        )
    } else {
        content = (
            <div></div>
        )
    }

    return (
        <div>
            {content}
        </div>
    )
}