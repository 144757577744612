import React from 'react';
import { Table } from 'react-bootstrap';

export function SimpleDataTable({ fields, data, keys }) {

    let tableHeader = [];
    for (let fieldName in fields) {
        if (fields.hasOwnProperty(fieldName)) {
            tableHeader.push((
                <th key={fieldName}>{fields[fieldName]}</th>
            ));
        }
    }

    let tableRows = [];
    for (let i = 0; i < data.length; i++) {
        let row = data[i];
        let pkStr = i;  // default key
        // primary key object
        let pk = {};
        if (keys) {
            for (let i = 0; i < keys.length; i++) {
                let keyField = keys[i];
                pk[keyField] = row[keyField];
            }
            // primary key as a string
            pkStr = JSON.stringify(pk);
            // console.log(pkStr)
        }

        let rowObj = [];
        for (let fieldName in fields) {
            if (fields.hasOwnProperty(fieldName)) {
                rowObj.push((
                    <td key={fieldName}>{row[fieldName]}</td>
                ));
            }
        }

        tableRows.push((
            <tr key={pkStr}>
                {rowObj}
            </tr>
        ));
    }

    return (
        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    {tableHeader}
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    );
}