import { authAxios } from '../../utils/AuthAxios';

export async function findOrderList() {
  let url = '/firefighter/fittingapp/findAllCanFittingOrder';
  return authAxios('GET', url);
}

export async function findFittingList(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/findFittingAppList?activePage=' + arg.pagingData.activePage + '&itemPerPage=10';
  if (arg.searchName) {
    url = url + '&searchKeys=firefighter_name&searchWord=' + arg.searchName;
  }
  return authAxios('GET', url);
}

export async function findFittingNotEqualList(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/findFittingAppNotEqualList';
  return authAxios('GET', url);
}

export async function findDetailsNeedBespoke(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/detailsNeedBespoke';
  return authAxios('GET', url);
}

export async function findFittingRecord(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/suits/' + arg.suitId;
  return authAxios('GET', url);
}

export async function saveFittingRecord(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/suits/' + arg.suitId;
  let body = { suitRecord: arg.suitRecord, fittingRecord: arg.fittingRecord }
  return authAxios('PUT', url, body);
}

export async function statOverviewCount(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/statOverviewCount';
  return authAxios('GET', url);
}

export async function statOverviewDetail(arg) {
  let url = '/firefighter/fittingapp/' + arg.orderId + '/statOverviewDetail';
  return authAxios('GET', url);
}