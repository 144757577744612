// Site environment (dev/qa/prod...) that will appear on the main page.
// export const env = 'DEV';
export const env = 'PROD';

// API host url:
// export const apiHost = 'http://localhost:8080';
export const apiHost = 'https://tkk-api.taiwankk.com';

// AAD ClientID
// export const clientId = 'cf7e14a9-f6d4-45a4-8bdb-7b67efd55745';
export const clientId = '7528e776-1ca7-46e6-af4a-e51899b442f2';