import React, { useState } from 'react';
import { Row, Col, Modal, Button, Form, Table, ToggleButton, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchFindFittingList,
    fetchFindFittingRecord,
    fetchSaveFittingRecord,
    changeActivePage,
    changeSelectedSuitId,
    changeSuitRecord,
    changeFittingRecord,
    changeShowSaveFittingRecord
} from './fittingSlice';
import { DataTable } from '../../component/DataTable';
import { ToastAlert } from './ToastAlert';
import {
    colorDisplay,
    sizingDisplay
} from '../../utils/Constants';
import {
    sleep
} from '../../utils/Utils';

import styles from './Fitting.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css'

export function FittingSuitList() {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const alertStyle = useSelector(state => state.fitting.alertStyle);
    const alertMessage = useSelector(state => state.fitting.alertMessage);

    const orderId = useSelector(state => state.fitting.orderId);
    const searchName = useSelector(state => state.fitting.searchName);
    const tableData = useSelector(state => state.fitting.tableData);
    const totalPage = useSelector(state => state.fitting.totalPage);
    const activePage = useSelector(state => state.fitting.activePage);
    const selectedSuitId = useSelector(state => state.fitting.selectedSuitId);
    const selectedSuit = useSelector(state => state.fitting.selectedSuit);
    const fittingRecord = useSelector(state => state.fitting.fittingRecord);
    const tableFields = {
        serial_no: '項次',
        display_name: '警義消',
        firefighter_division: '大隊',
        firefighter_squadron: '分隊',
        firefighter_title: '職稱',
        firefighter_name: '姓名',
        need_fit_cnt: '應套量',
        fitted_cnt: '已套量'
    };
    const keys = ['serial_no', 'suit_id'];
    const buttonIcon = "fas fa-ruler";

    const pageItemOnClick = (page) => {
        let pagingData = { activePage: page }
        dispatch(changeActivePage(page))
        dispatch(fetchFindFittingList({ orderId: orderId, pagingData: pagingData, searchName: searchName }));
    }

    const buttonOnClick = async (pkStr) => {
        // console.log(pkStr);
        // console.log(JSON.parse(pkStr));
        let suitId = JSON.parse(pkStr).suit_id
        // console.log(suitId);
        dispatch(changeSelectedSuitId(suitId));
        dispatch(fetchFindFittingRecord({ orderId: orderId, suitId: suitId }));
        dispatch(changeShowSaveFittingRecord(false));
        await sleep(300);
        handleShowModal();
    }

    const rowButtonOnClick = (row, type, value) => {
        dispatch(changeFittingRecord({ row: row, type: type + '_value', value: value }))
    }

    const rowNoteChange = (row, value) => {
        dispatch(changeFittingRecord({ row: row, type: 'note_value', value: value }))
    }

    const rowButtonClear = (row) => {
        dispatch(changeFittingRecord({ row: row, type: 'color_value', value: null }))
        dispatch(changeFittingRecord({ row: row, type: 'size_value', value: null }))
        dispatch(changeFittingRecord({ row: row, type: 'note_value', value: '' }))
    }

    const suitRecordOnChange = (type, value) => {
        dispatch(changeSuitRecord({ type: type, value: value }))
    }

    const saveFitting = async (fittingRecord) => {
        dispatch(fetchSaveFittingRecord({ orderId: orderId, suitId: selectedSuitId, suitRecord: selectedSuit, fittingRecord: fittingRecord }))
        await sleep(500);
        dispatch(fetchFindFittingList({ orderId: orderId, pagingData: { activePage: activePage } }));
    }

    // 套量資料 顯示
    let fittingRow = [];
    let fittingBodyTr = [];
    fittingRow.push(
        (
            <thead>
                <tr>
                    <th></th>
                    <th>顏色</th>
                    <th>尺吋</th>
                    <th>備註</th>
                    <th>完成</th>
                    <th></th>
                </tr>
            </thead>
        )
    );

    // console.log(detailsNeedBespoke);
    // console.log(fittingRecord);
    for (var row = 0; row < fittingRecord.length; row++) {
        let bespokeArr = fittingRecord[row];
        // console.log(bespokeArr)
        // console.log(bespokeArr['color_value']);
        let multiDetailTd = [];
        let k = row;

        if (bespokeArr.bespoke_display) {
            let toggleButtonDetailArr = [];
            // console.log(bespokeArr.bespoke_display);
            // console.log(bespokeArr.gear_name);
            let detailSplit = bespokeArr.bespoke_display.split(',');
            // console.log(detailSplit.length);
            // console.log(detailBga);
            // console.log(_array_start + ":" + _array_end);
            let detailRowId = 'detail_' + row;
            for (let j = 0; j < detailSplit.length; j++) {
                let value = detailSplit[j]  // ex: 1__紅 (用「__」分隔)
                let valueSplit = value.split('__');
                let detailId = detailRowId + "_" + value;
                let checked = (parseInt(valueSplit[0], 10) === bespokeArr['detail_value']);
                toggleButtonDetailArr.push(
                    (
                        <ToggleButton
                            id={detailId}
                            type="radio"
                            variant='outline-primary'
                            name={detailId}
                            value={valueSplit[0]}
                            checked={checked}
                            onClick={() => rowButtonOnClick(k, 'detail', parseInt(valueSplit[0], 10))}
                        > {valueSplit[1]}
                        </ToggleButton>
                    )
                );
            }
            multiDetailTd.push(
                (
                    <>
                        {toggleButtonDetailArr}
                    </>
                )
            );
        }
        // 顏色
        let colorTd = [];
        if (bespokeArr.fitting_color === 'Y') {

            let toggleButtonColorArr = [];
            // console.log(bespokeArr.color_values);
            let colorSplit = bespokeArr.color_values.split(',');
            let colorRowId = 'color_' + row;
            for (let j = 0; j < colorSplit.length; j++) {
                let value = colorSplit[j]
                let colorId = colorRowId + "_" + value;
                // console.log(value);
                // console.log(bespokeArr);
                let checked = (value === bespokeArr['color_value']);
                toggleButtonColorArr.push(
                    (
                        <ToggleButton
                            id={colorId}
                            type="radio"
                            variant='outline-primary'
                            name={colorId}
                            value={value}
                            checked={checked}
                            onClick={() => rowButtonOnClick(k, 'color', value)}
                        > {colorDisplay(value)}
                        </ToggleButton>
                    )
                );
            }
            colorTd.push(
                (
                    <>
                        {toggleButtonColorArr}
                    </>
                )
            );
        }
        // 尺吋
        let sizeTd = [];
        if (bespokeArr.fitting_size === 'Y') {
            let toggleButtonSizeArr = [];
            // console.log(bespokeArr.color_values);
            let sizeSplit = bespokeArr.size_values.split(',');
            // console.log(sizeSplit.length);
            let sizeRowId = 'size_' + row;
            for (let j = 0; j < sizeSplit.length; j++) {
                let value = sizeSplit[j]
                let sizeId = sizeRowId + "_" + value;
                let checked = (value === bespokeArr['size_value']);
                toggleButtonSizeArr.push(
                    (
                        <ToggleButton
                            id={sizeId}
                            type="radio"
                            variant='outline-primary'
                            name={sizeId}
                            value={value}
                            checked={checked}
                            onClick={() => rowButtonOnClick(k, 'size', value)}
                        > {sizingDisplay(value)}
                        </ToggleButton>
                    )
                );
            }
            sizeTd.push(
                (
                    <>
                        {toggleButtonSizeArr}
                    </>
                )
            );
        }

        let fittedTd = []
        if (bespokeArr.fitted === 'Y') {
            fittedTd = (
                <i className="fas fa-check"></i>
            )
        }

        if (bespokeArr && !bespokeArr['note_value']) {
            dispatch(changeFittingRecord({ row: k, type: 'note_value', value: '' }))
        }

        fittingBodyTr.push(
            (
                <tr key={bespokeArr.gear_name}>
                    <td style={{ width: '10%' }}>{bespokeArr.alias_name}</td>
                    <td style={{ width: '20%' }}>{colorTd}</td>
                    <td style={{ width: '20%' }}>{sizeTd}</td>
                    <td style={{ width: '15%' }}>
                        <Form.Control as="textarea" rows={3} value={bespokeArr['note_value']} onChange={(event) => {
                            rowNoteChange(k, event.target.value);
                        }} />
                    </td>
                    <td style={{ width: '6%', textAlign: 'center', color: '#0D6EFD' }}>
                        {fittedTd}
                    </td>
                    <td style={{ width: '5%' }}>
                        <Button variant="outline-danger" onClick={() => {
                            rowButtonClear(k);
                        }}>清除</Button>
                    </td>
                </tr>
            )
        );
    }

    fittingRow.push(
        (
            <tbody>
                {fittingBodyTr}
            </tbody>
        )
    );

    let content = [];
    if (alertMessage) {
        content = (
            <Alert variant={alertStyle}>
                {alertMessage}
            </Alert>
        )
    } else if (orderId) {
        content = (
            <DataTable
                fields={tableFields}
                data={tableData}
                keys={keys}
                totalPage={totalPage}
                activePage={activePage}
                pageItemOnClick={pageItemOnClick}
                buttonIcon={buttonIcon}
                buttonText={"套量"}
                buttonOnClick={buttonOnClick}
            />
        )
    } else {
        content = (
            <div></div>
        )
    }

    return (
        <div>
            <Row>
                <Col>
                    {content}
                </Col>
            </Row>

            <Modal
                show={showModal}
                fullscreen={true}
                backdrop="static"
                keyboard={false}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>套量 - {selectedSuit.serial_no} - {selectedSuit.firefighter_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        {fittingRow}
                    </Table>
                    <Row>
                        <Col xs={6} md={3}>
                            <Form.Group controlId="body_height">
                                <Form.Label>身高</Form.Label>
                                <Form.Control type="text" value={selectedSuit.body_height} onChange={(event) => {
                                    suitRecordOnChange('body_height', event.target.value);
                                }} />
                            </Form.Group>
                        </Col>
                        <Col xs={6} md={3}>
                            <Form.Group controlId="body_weight">
                                <Form.Label>體重</Form.Label>
                                <Form.Control type="text" value={selectedSuit.body_weight} onChange={(event) => {
                                    suitRecordOnChange('body_weight', event.target.value);
                                }} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Label>簽名</Form.Label><br />
                            <img src={selectedSuit.signature} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={() => {
                        saveFitting(fittingRecord);
                    }}>
                        完成套量
                    </Button>
                </Modal.Footer>
                <ToastAlert />
            </Modal>
        </div>
    );
}