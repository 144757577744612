import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';
import {
    changeShowSaveFittingRecord
} from './fittingSlice';

export function ToastAlert() {
    const dispatch = useDispatch();
    const show = useSelector(state => state.fitting.showSaveFittingRecord);
    const variant = useSelector(state => state.fitting.toastAlertStyle);
    const message = useSelector(state => state.fitting.toastAlertMessage);

    const setShow = (show) => {
        dispatch(changeShowSaveFittingRecord(show));
    }

    let headerBackgroundColor = '#555555';
    let bodyBackgroundColor = '#FFFFFF';

    if (variant === 'success') {
        headerBackgroundColor = '#009400';
        bodyBackgroundColor = '#E6F6E6';
    } else if (variant === 'warning') {
        headerBackgroundColor = '#E6A700';
        bodyBackgroundColor = '#FFF8E6';
    } else if (variant === 'danger') {
        headerBackgroundColor = '#E13238';
        bodyBackgroundColor = '#FFEBEC';
    }

    return (
        <div className="position-relative">
            <ToastContainer className="p-3" position='bottom-end' style={{ marginBottom: 70 }}>
                <Toast bg="success" onClose={() => setShow(false)} show={show} delay={2500} autohide>
                    <Toast.Header style={{ backgroundColor: headerBackgroundColor, color: 'white' }}>
                        <strong className="me-auto">訊息</strong>
                    </Toast.Header>
                    <Toast.Body style={{ backgroundColor: bodyBackgroundColor }}>{message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}