import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Fitting } from './features/fitting/Fitting';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { authContext } from './utils/AzureAd';
import { env } from './config';

function App() {

  let user = authContext.getCachedUser();
  if (!user) {
    authContext.login();
  }
  let userName = user.profile.name;

  const logout = () => {
    authContext.logOut();
  }

  let showEnv = null;
  if (env !== 'PROD') {
    showEnv = (
      <b style={{ color: 'red' }}>[{env}]&nbsp;</b>
    )
  }

  return (
    <div>
      <Navbar style={{ backgroundColor: '#1c9bcf' }} variant="dark" >
        <Container>
          <Navbar.Brand><img src="logo36.png" />&nbsp;&nbsp;&nbsp;{showEnv}<b>固巧快 消防衣套量(業務用)</b></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {userName}&nbsp;&nbsp;&nbsp;&nbsp;<a onClick={logout} style={{ cursor: 'pointer' }}><i className="fas fa-sign-out-alt"></i></a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Fitting />
    </div>
  );
}

export default App;
