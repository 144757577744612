import React from 'react';
import { Table, Pagination, Button } from 'react-bootstrap';

import '@fortawesome/fontawesome-free/css/all.min.css'

function genPaginationItem(page, activePage, pageItemOnClick) {
    if (page === activePage) {
        return (<Pagination.Item active onClick={() => pageItemOnClick(page)} key={page}>{page}</Pagination.Item>)
    } else {
        return (<Pagination.Item onClick={() => pageItemOnClick(page)} key={page}>{page}</Pagination.Item>)
    }
}

export function DataTable({ fields, data, keys, totalPage, activePage, pageItemOnClick, buttonIcon, buttonText, buttonOnClick }) {

    let tableHeader = [];
    for (let fieldName in fields) {
        if (fields.hasOwnProperty(fieldName)) {
            tableHeader.push((
                <th key={fieldName}>{fields[fieldName]}</th>
            ));
        }
    }
    tableHeader.push((
        <th key="btnTh">&nbsp;</th>
    ));
    let tableRows = [];
    for (let i = 0; i < data.length; i++) {
        let row = data[i];
        let pkStr = i;  // default key
        // primary key object
        let pk = {};
        if (keys) {
            for (let i = 0; i < keys.length; i++) {
                let keyField = keys[i];
                pk[keyField] = row[keyField];
            }
            // primary key as a string
            pkStr = JSON.stringify(pk);
            // console.log(pkStr)
        }

        // button
        let btnTd = null
        if (buttonIcon) {
            btnTd = (
                <Button variant="outline-primary"><i className={buttonIcon}></i>{' '}{buttonText}</Button>
            )
        }

        let rowObj = [];
        for (let fieldName in fields) {
            if (fields.hasOwnProperty(fieldName)) {
                rowObj.push((
                    <td key={fieldName}>{row[fieldName]}</td>
                ));
            }
        }
        if (buttonIcon) {
            rowObj.push((
                <td key='btn' onClick={() => buttonOnClick(pkStr)}>
                    {btnTd}
                </td>
            ));
        }

        tableRows.push((
            <tr key={pkStr}>
                {rowObj}
            </tr>
        ));
    }

    // 分頁
    let paginationItem = [];
    if (totalPage <= 6) {
        for (let i = 1; i <= totalPage; i++) {
            paginationItem.push(genPaginationItem(i, activePage, pageItemOnClick))
        }
    } else if (activePage <= 3) {
        for (let i = 1; i <= 5; i++) {
            paginationItem.push(genPaginationItem(i, activePage, pageItemOnClick))
        }
        paginationItem.push(<Pagination.Ellipsis />);
        paginationItem.push(genPaginationItem(totalPage, activePage, pageItemOnClick))
    } else if (activePage >= (totalPage - 2)) {
        paginationItem.push(genPaginationItem(1, activePage, pageItemOnClick))
        paginationItem.push(<Pagination.Ellipsis />);
        for (let i = (totalPage - 4); i <= totalPage; i++) {
            paginationItem.push(genPaginationItem(i, activePage, pageItemOnClick))
        }
    } else {
        paginationItem.push(genPaginationItem(1, activePage, pageItemOnClick))
        paginationItem.push(<Pagination.Ellipsis />);
        for (let i = (activePage - 2); i <= (activePage + 2); i++) {
            paginationItem.push(genPaginationItem(i, activePage, pageItemOnClick))
        }
        paginationItem.push(<Pagination.Ellipsis />);
        paginationItem.push(genPaginationItem(totalPage, activePage, pageItemOnClick))
    }

    let paginationDiv = (
        <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            {paginationItem}
            <Pagination.Next />
            <Pagination.Last />
        </Pagination>
    )

    return (
        <div>
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        {tableHeader}
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <div style={{ float: 'right' }}>
                {paginationDiv}
            </div>
        </div>
    );
}