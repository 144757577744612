import React from 'react';
import { useSelector } from 'react-redux';
import { SimpleDataTable } from '../../component/SimpleDataTable';

export function FittingDetail() {

    const statOverviewDetail = useSelector(state => state.fitting.statOverviewDetail);
    let content = [];
    if (statOverviewDetail) {
        for (let i = 0; i < statOverviewDetail.length; i++) {
            let gearName = statOverviewDetail[i]['gearName'];
            let specId = statOverviewDetail[i]['specId'];
            let fields = generateFields(statOverviewDetail[i]['fields']);
            let data = statOverviewDetail[i]['statDetailCount'];
            content.push(
                <div key={i}><span style={{ color: '#5871B8' }}>{gearName} - {specId} 明細</span>
                    <SimpleDataTable
                        fields={fields}
                        data={data}
                    />
                </div>
            );
        }
    }

    function generateFields(fieldList) {
        let fields = {};
        for (let i = 0; i < fieldList.length; i++) {
            let field = fieldList[i];
            fields[field['key']] = field['value'];
        }
        return fields;
    }

    return (
        <div>
            {content}
        </div>
    )

}